const InviteTemplate = (
    first_name,
    last_name,
    email_address,
    event
  ) => {
    return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head> <!--[if gte mso 9]> <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> 
      </o:OfficeDocumentSettings> </xml> <![endif]--><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"><meta name="x-apple-disable-message-reformatting">
      <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"> <!--<![endif]--><title></title><style type="text/css">
      a{color:#161a39;text-decoration:underline}@media only screen and (min-width: 620px){.u-row{width:600px !important}
      .u-row .u-col{vertical-align:top}.u-row .u-col-100{width:600px !important}}@media (max-width: 620px){.u-row-container
      {max-width:100% !important;padding-left:0px !important;padding-right:0px !important}.u-row .u-col
      {min-width:320px !important;max-width:100% !important;display:block !important}.u-row{width:calc(100% - 40px) !important}
      .u-col{width:100% !important}.u-col>div{margin:0 auto}}body{margin:0;padding:0}table,tr,td{vertical-align:top;border-collapse:collapse}
      p{margin:0}.ie-container table, .mso-container table{table-layout:fixed}*{line-height:inherit}a[x-apple-data-detectors='true']
      {color:inherit !important;text-decoration:none !important}</style><!--[if !mso]><!-->
      <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet" type="text/css">
      <!--<![endif]--></head><body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #f9f9f9"> 
      <!--[if IE]><div class="ie-container"><![endif]--> <!--[if mso]><div class="mso-container"><![endif]-->
      <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;
      vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #f9f9f9;width:100%" cellpadding="0" cellspacing="0">
      <tbody><tr style="vertical-align: top"><td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top"> 
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" 
      style="background-color: #f9f9f9;"><![endif]--><div class="u-row-container" style="padding: 0px;background-color: #f9f9f9">
      <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;
      word-break: break-word;background-color: #f9f9f9;"><div style="border-collapse: collapse;display: table;width: 100%;
      background-color: transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr><td style="padding: 0px;background-color: #f9f9f9;" align="center"><table cellpadding="0" cellspacing="0" border="0" 
      style="width:600px;"><tr style="background-color: #f9f9f9;"><![endif]--><!--[if (mso)|(IE)]><td align="center" 
      width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;
      border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]--><div class="u-col u-col-100" 
      style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;"><div style="width: 100% !important;"> 
      <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;
      border-right: 0px solid transparent;border-bottom: 0px solid transparent;"> <!--<![endif]--><table style="font-family:'Lato',
      sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0"><tbody><tr><td 
      style="overflow-wrap:break-word;word-break:break-word;padding:15px;font-family:'Lato',sans-serif;" align="left">
      <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;
      table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;
      border-top: 1px solid #f9f9f9;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%"><tbody><tr style="vertical-align: top">
      <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;
      mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%"> <span>&#160;</span></td></tr></tbody>
      </table></td></tr></tbody></table><!--[if (!mso)&(!IE)]><!--></div> <!--<![endif]--></div></div> <!--[if (mso)|(IE)]>
      </td><![endif]--> <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]--></div></div></div><div 
      class="u-row-container" style="padding: 0px;background-color: transparent"><div class="u-row" style="Margin: 0 auto;
      min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
      <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;"> <!--[if (mso)|(IE)]>
      <table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" 
      align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #ffffff;">
      <![endif]--><!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;
      border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top">
      <![endif]--><div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
      <div style="width: 100% !important;"> <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;
      border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"> <!--<![endif]-->
      <table style="font-family:'Lato',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0"><tbody>
      <tr><td style="overflow-wrap:break-word;word-break:break-word;padding:25px 0px;font-family:'Lato',sans-serif;" align="left">
      <table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 20px; padding-left: 30px;" align="left">
      <img align="left" border="0" src=${event?.event_details?.featured_image} alt="Image" title="Image" style="outline: none;
      text-decoration: none;-ms-interpolation-mode: bicubic;clear: both; display: inline-block !important;border: none;height: auto;
      float: none;width: 53%;max-width: 307.4px; border-radius: 4px;" width="307.4" /></td></tr></table></td></tr></tbody></table>
      <!--[if (!mso)&(!IE)]><!--></div> <!--<![endif]--></div></div> <!--[if (mso)|(IE)]></td><![endif]--> <!--[if (mso)|(IE)]></tr>
      </table></td></tr></table><![endif]--></div></div></div><div class="u-row-container" style="padding: 0px;
      background-color: transparent"><div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;
      word-wrap: break-word;word-break: break-word;background-color: #ffffff;"><div style="border-collapse: collapse;display: table;
      width: 100%;background-color: transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" 
      style="width:600px;"><tr style="background-color: #ffffff;"><![endif]--><!--[if (mso)|(IE)]><td align="center" width="600" 
      style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;
      border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]--><div class="u-col u-col-100" 
      style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;"><div style="width: 100% !important;"> 
      <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;
      border-right: 0px solid transparent;border-bottom: 0px solid transparent;"> <!--<![endif]--><table style="font-family:'Lato',
      sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0"><tbody><tr><td style="overflow-wrap:break-word;
      word-break:break-word;padding:10px 40px 30px;font-family:'Lato',sans-serif;" align="left"><div style="color: #000000; 
      line-height: 140%; text-align: left; word-wrap: break-word;"><p style="font-size: 14px; line-height: 140%;"><span 
      style="font-size: 18px; line-height: 25.2px; color: #34495e; font-family: helvetica, sans-serif;">Hi, ${first_name} ${last_name}
      </span></p><p style="font-size: 14px; line-height: 140%;">&nbsp;</p><p style="font-size: 14px; line-height: 140%;">
      <span style="font-family: helvetica, sans-serif; font-size: 18px; line-height: 25.2px; color: #34495e;">You are invited to be a 
      visitor at ${event?.event_details["EN"]?.event_name}. </span></p><p style="font-size: 14px; line-height: 140%;"> <br /> 
      <span style="font-family: helvetica, sans-serif; font-size: 18px; line-height: 25.2px; color: #34495e;">Please register using the 
      <strong style="background-color: #FFF000;">same email address</strong> where you are recieving this email invite. </span></p></div>
      </td></tr></tbody></table><table style="font-family:'Lato',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" 
      width="100%" border="0"><tbody><tr><td style="overflow-wrap:break-word;word-break:break-word;padding:0px 40px;font-family:'Lato',
      sans-serif;" align="left"><div align="left"> <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" 
      style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:'Lato',sans-serif;">
      <tr><td style="font-family:'Lato',sans-serif;" align="left"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" 
      xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:51px; v-text-anchor:middle; width:253px;" arcsize="2%" 
      stroke="f" fillcolor="#3598db"><w:anchorlock/><center style="color:#FFFFFF;font-family:'Lato',sans-serif;"><![endif]--> 
      <a href="https://platform.igesia.co/event/${event?.slug}/register/${email_address}" target="_blank" 
      style="box-sizing: border-box;display: inline-block;font-family:'Lato',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;
      text-align: center;color: #FFFFFF; background-color: #3598db; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; 
      width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;"> 
      <span style="display:block;padding:15px 40px;line-height:120%;"><strong><span style="font-size: 18px; line-height: 21.599999999999998px;">
      Register now</span></strong></span> </a> <!--[if mso]></center></v:roundrect></td></tr></table><![endif]--><p style="font-size: 14px; 
      line-height: 140%; margin-top:30px"> <br /> Please write to <a href="mailto:support@igesia.co"> support@igesia.co</a> for assistance. 
      <br /></p></div></td></tr></tbody></table><table style="font-family:'Lato',sans-serif;" role="presentation" cellpadding="0" 
      cellspacing="0" width="100%" border="0"><tbody><tr><td style="overflow-wrap:break-word;word-break:break-word;padding:40px 40px 30px;
      font-family:'Lato',sans-serif;" align="left"><div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
      <p style="font-size: 14px; line-height: 140%;"><span style="color: #888888; font-size: 14px; line-height: 19.599999999999998px; 
      font-family: helvetica, sans-serif;"><em><span style="line-height: 19.599999999999998px; font-size: 14px;">Event powered by 
      <a href="http://events.igesia.co/" target="_blank" rel="noopener">igesia</a></span></em></span></p><p style="font-size: 14px; 
      line-height: 140%;"><span style="font-family: helvetica, sans-serif; font-size: 14px; line-height: 19.599999999999998px;">&copy; 
      [igesia] 2021. All rights reserved.</span><br /><span style="color: #888888; font-size: 14px; line-height: 19.599999999999998px; 
      font-family: helvetica, sans-serif;"><em><span style="font-size: 16px; line-height: 22.4px;">&nbsp;</span></em></span></p></div></td>
      </tr></tbody></table><!--[if (!mso)&(!IE)]><!--></div> <!--<![endif]--></div></div> <!--[if (mso)|(IE)]></td><![endif]--> 
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]--></div></div></div><div class="u-row-container" style="padding: 0px;
      background-color: transparent"><div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;
      word-wrap: break-word;word-break: break-word;background-color: #f9f9f9;"><div style="border-collapse: collapse;display: table;
      width: 100%;background-color: transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" 
      style="width:600px;"><tr style="background-color: #f9f9f9;"><![endif]--><!--[if (mso)|(IE)]><td align="center" width="600" 
      style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;
      border-bottom: 0px solid transparent;" valign="top"><![endif]--><div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;
      isplay: table-cell;vertical-align: top;"><div style="width: 100% !important;"> <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;
      border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"> 
      <!--<![endif]--><table style="font-family:'Lato',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" 
      border="0"><tbody><tr><td style="overflow-wrap:break-word;word-break:break-word;padding:0px 40px 30px 20px;font-family:'Lato',
      sans-serif;" align="left"><div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;"></div></td></tr>
      </tbody></table><!--[if (!mso)&(!IE)]><!--></div> <!--<![endif]--></div></div> <!--[if (mso)|(IE)]></td><![endif]--> 
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]--></div></div></div><!--[if (mso)|(IE)]></td></tr></table>
      <![endif]--></td></tr></tbody></table> <!--[if mso]></div><![endif]--> <!--[if IE]></div><![endif]--></body></html>`;
  };
  
  export default InviteTemplate;
  