import React, { useState, useEffect, useMemo } from "react";
import EventLeftbar from "../LeftBar";
import EventNavbar from "../NavBar";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Dropdown,
  Modal,
  ButtonGroup,
  Card,
  Badge,
} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import firebase from "firebase";
import moment from "moment";
import Tier from "./Tier";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {
  faTimes,
  faComment,
  faTrash,
  faArrowLeft,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ErrcGrid from "./ErrcGrid";
import ToBe from "./To-be";
import Table from "./Table";
import SixPath from "./Sixpath";
import EventChat from "../EventChat";
import { useHistory, useParams } from "react-router";
import AsIsConclusions from "./AsIsConclusions";
import KeyNeedOfNonCustomers from "./KeyNeedOfNonCustomers";
import ProjectSettings from "./ProjectSettings";
import IdeationTable from "./IdeationTable";
import { handleExportJpeg, handleExportPng } from "../../../../utils";

HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

var yourLabels = ["Low", "Medium", "High"];

function Project(props) {
  const { id, appId } = useParams();
  let history = useHistory();
  const currentUser = firebase.auth().currentUser;
  const [title, setTitle] = useState("");
  const [industry, setIndustry] = useState("");
  const [currentCustomers, setCurrentCustomers] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");
  const [errcCreated, setErrcCreated] = useState(false);
  const [toBeCreated, setToBeCreated] = useState(false);
  const [buyerExpAuditCreated, setBuyerExpAuditCreated] = useState(false);
  const [threeTier, setThreeTier] = useState(false);
  const [sixPath, setSixPath] = useState(false);
  const [updateSixPath, setUpdateSixPath] = useState(false);
  const [updatingSixPath, setUpdatingSixPath] = useState(false);
  const [showOldSixPath, setShowOldSixPath] = useState(false);
  const [toBe, setToBe] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const [showShare, setShowShare] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [currentCords, setCurrentCords] = useState([]);
  const [showTierUpdateModal, setShowTierUpdateModal] = useState(false);
  const [selectedCords, setSelectedCords] = useState(null);
  const [showColorPickerForTier, setShowColorPickerForTier] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToCategory, setAssignedToCategory] = useState("");
  const [assignedToAll, setAssignedToAll] = useState(false);
  const [showProTips, setShowProTips] = useState(true);

  const {
    darkTheme,
    currentEvent,
    graphId,
    asisFactors,
    factors,
    products,
    setGraphId,
    setFactors,
    setAsIsFactors,
    setProducts,
  } = props;

  const [coordinateValue, coodinateValueLoading, coodinateValueError] =
    useCollection(
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/three_tiers/`)
    );

  const coordinateArray = coordinateValue?.docs?.map((item) => {
    return { ...item.data(), id: item.id };
  });

  useEffect(() => {
    if (coordinateValue) {
      let data = coordinateArray;
      setCurrentCords(data);
    }
  }, [JSON.stringify(coordinateValue)]);

  useEffect(() => {
    if (appId) {
      setGraphId(appId);
    }
  }, []);

  const [usersSnapshot, userLoading, userError] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/users`)
      .where("uid", "!=", currentUser.uid)
  );

  const allUsers = useMemo(() => {
    return usersSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        label: fac?.data()?.firstName,
        value: fac?.data()?.firstName,
        ...fac.data(),
      };
    });
  }, [usersSnapshot]);

  const [bboaDoc, bboaLoading, bboaError] = useDocument(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa`)
      .doc(`${graphId}`)
  );

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  useEffect(() => {
    if (!bboaLoading) {
      // if (bboaDoc?.data()?.title) {
      setTitle(bboaDoc?.data()?.title);
      setCurrentCustomers(bboaDoc?.data()?.current_customers);
      setIndustry(bboaDoc?.data()?.industry);
      setErrcCreated(bboaDoc?.data()?.errc_created);
      setToBe(bboaDoc?.data()?.to_be_created);
      setBuyerExpAuditCreated(bboaDoc?.data()?.buyerExpAudit_created);
      setThreeTier(bboaDoc?.data()?.three_tiers);
      setSixPath(bboaDoc?.data()?.six_path);
      setUpdateSixPath(bboaDoc?.data()?.six_path_new);
      setCreatedAt(bboaDoc?.data()?.createdAt);
      setCreatedBy(bboaDoc?.data()?.createdBy);
      setAssignedTo(bboaDoc?.data()?.assignedTo);
      setAssignedToAll(bboaDoc?.data()?.assignedToAll);
      setAssignedToCategory(bboaDoc?.data()?.assignedToCategory);
      // }
    }
  }, [bboaDoc]);

  const [factorsCollection, factorsCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
  );

  const factorsArray = factorsCollection?.docs
    ?.sort((a, b) => a.position - b.position)
    ?.map((item) => {
      return { ...item.data(), id: item.id };
    });

  useEffect(() => {
    if (factorsArray) {
      let data = factorsArray;
      let data2 = factorsArray.filter((f) => !f.isNew);
      setFactors([...data]);
      setAsIsFactors([...data2]);
    }
  }, [factorsCollection]);

  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/as_is`)
  );

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${currentEvent?.id}/bboa/${graphId}/as_is/${item.id}/values`
              );

            const data = await Promise.all(
              asisFactors?.map(async (factor) => {
                const doc = await docRef.doc(factor.id).get();
                if (doc.exists) {
                  return doc.data().value;
                } else {
                  return factor.defaultValue;
                }
              })
            );
            if (data.length === asisFactors.length) {
              return {
                ...item.data(),
                id: item.id,
                data: data,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              };
            }
          })
        );
        setProducts(collectionData);
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };

  // useEffect(() => {
  //   setArray((prev) => {
  //     const newState = prev.map((product) => ({name: product.name}))
  //   })
  // },[products])

  useEffect(() => {
    if (!productCollectionLoading) {
      getYDataHandler();
    }
  }, [productCollectionLoading, productCollection]);

  // useEffect(() => {
  //   if (products.length && factors.length) {

  //   }
  // })
  // console.log(factors, props.series)
  const [dynamicOption, setDynamicOption] = useState({
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      // renderTo: "container",
      // animation: false,
    },
    xAxis: [],
    series: [],
  });

  useEffect(() => {
    setDynamicOption((prev) => {
      const newState = {
        ...prev,
        xAxis: asisFactors.map((x) => x.name),
        series: products,
      };
      return newState;
    });
  }, [JSON.stringify(products), JSON.stringify(asisFactors)]);

  let options = {
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: industry,
    },
    xAxis: {
      categories: dynamicOption.xAxis,
      title: {
        text: "Key Elements",
      },
    },

    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
      title: {
        text: "Offering Level",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            drag: function (e) {
              // console.log(e)
            },
            drop: function (e) {
              // console.log(
              //   e.target,
              //   e.target.category,
              //   e.target.y,
              //   e.target.series.userOptions.id,
              //   factorsArray
              // );
              let key = e.target.series.userOptions.id;
              let value = factorsArray.filter(
                (x) => x.name === e.target.category
              );
              // console.log(value, key, graphId);
              let document = firebase
                .firestore()
                .collection(
                  `events/${currentEvent?.id}/bboa/${graphId}/as_is/${key}/values`
                )
                .doc(value[0].id);
              if (document && document.exists) {
                document.update({
                  value: e.target.y,
                });
              } else {
                document.set({
                  value: e.target.y,
                });
              }
            },
          },
        },
        stickyTracking: false,
      },
      column: {
        stacking: "normal",
      },
      line: {
        cursor: "ns-resize",
      },
    },
    series: dynamicOption.series,
  };

  function handleOnDragEnd({ destination, source }) {
    //console.log(destination, source);
    let sourceIndex = asisFactors[source.index];
    let destinationIndex = asisFactors[destination.index];
    // console.log(sourceIndex.id, sourceIndex.position, "source");
    // console.log(destinationIndex.id, destinationIndex.position, "destination");

    if (destination) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .doc(sourceIndex.id)
        .set(
          {
            position: destinationIndex.position,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .doc(destinationIndex.id)
        .set(
          {
            position: sourceIndex.position,
          },
          {
            merge: true,
          }
        );
    }

    const item = Array.from(
      products?.map((x, key) => {
        const itemData = x;
        const [reorderedData] = itemData.data.splice(source.index, 1);
        itemData.data.splice(destination.index, 0, reorderedData);
        return itemData;
      })
    );
  }

  const handleSelectedShare = (graphId) => {
    if (selectedUsers.length > 0) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${graphId}`)
        .update(
          {
            assignedTo: firebase.firestore.FieldValue.arrayUnion(
              ...selectedUsers
            ),
            assignedToAll: false,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
        })
        .catch((error) => console.log(error));
    }

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${graphId}`)
        .update({
          assignedToCategory: selectedAttendeeCategory,
          assignedToAll: false,
        })
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedAttendeeCategory("");
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }
  };

  const revokeAccessFromAll = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          assignedToAll: false,
          assignedToCategory: "",
        })
        .then(() => {
          toast.success("Revoked Access successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const createErrcGrid = () => {
    if (graphId) {
      let idArray = factors.map((x) => x.id);
      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);
      docRef
        .doc(graphId)
        .update({
          errc_created: true,
        })
        .then(() => {
          firebase
            .firestore()
            .collection(`events/${currentEvent?.id}/bboa/${graphId}/errc_grid`)
            .doc("0")
            .set({
              main: idArray.length ? idArray : [],
              eliminate: [],
              raise: [],
              reduce: [],
              create: [],
            });
        });
    }
  };

  const createThreeTier = () => {
    if (graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);
      docRef
        .doc(graphId)
        .update({
          three_tiers: true,
        })
        .then(() => {
          // firebase
          //   .firestore()
          //   .collection(
          //     `events/WeMOfCPQp0QLivd1bo4K/bboa/${graphId}/three_tiers`
          //   )
          //   .doc("0")
          //   .set({
          //     coordinates: []
          //   });
        });
    }
  };

  const createBuyerExpAudit = () => {
    let heads = [
      {
        name: "Purchase",
        description: "Action of buying something",
      },
    ];
    let rows = [
      {
        id: 0,
        name: "PRODUCTIVITY / MONEY",
        description: "Makes me get (lose) money",
        type: 0,
        logo: "productivity-money",
      },
      {
        id: 1,
        name: "PRODUCTIVITY / TIME",
        description: "Makes me get (lose) time",
        type: 0,
        logo: "productivity-time",
      },
      {
        id: 2,
        name: "SIMPLICITY",
        description:
          "Represents an intellectual and/or physical easiness (difficulty)",
        type: 0,
        logo: "simplicity",
      },
      {
        id: 3,
        name: "CONVENIENCE / TIME",
        description: "Anytime I want (or opposite)",
        type: 0,
        logo: "convenience-time",
      },
      {
        id: 4,
        name: "CONVENIENCE / LOCATION",
        description: "Any where I want (or opposite)",
        type: 0,
        logo: "convenience-location",
      },
      {
        id: 5,
        name: "RISK",
        description:
          "Reduces (improves) real and/or perceived risks regarding humans and/or goods integrity",
        type: 1,
        logo: "risk",
      },
      {
        id: 6,
        name: "FUN",
        description: "gives me fun / entertain me (bores me)",
        type: 1,
        logo: "fun",
      },
      {
        id: 7,
        name: "IMAGE",
        description: "Improves (decreases) my own image",
        type: 1,
        logo: "image",
      },
      {
        id: 8,
        name: "ETHIC / ENVIRONMENT",
        description:
          "Allows for (dismisses) environmental – ethical friendliness",
        type: 1,
        logo: "ethic-environment",
      },
    ];
    if (graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);
      docRef
        .doc(graphId)
        .update({
          buyerExpAudit_created: true,
        })
        .then(() => {
          firebase
            .firestore()
            .collection(
              `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit`
            )
            .doc("0")
            .set({
              rows: rows,
            });
        });

      let docRef2 = firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
        );
      let user = firebase.auth().currentUser;
      heads.map((head, index) => {
        docRef2.doc(`${index + 1}`).set({
          name: head.name,
          description: head.description,
          created_at: new Date(),
          created_by: {
            name: user.displayName,
            email: user.email,
            id: user.uid,
          },
        });
      });
    }
  };
  const createSixPath = () => {
    let paths = [
      {
        name: "Time Trends",
        logo: "time-trends",
        head: [
          {
            id: 0,
            name: "How can I create unrivalled value for the customer by becoming an actor of the trends that impact my market?",
          },
        ],
      },
      {
        name: "Alternative Industries",
        logo: "alternative-industries",
        head: [
          {
            id: 0,
            name: "Why do my noncustomers choose these alternative solutions?",
          },
          {
            id: 1,
            name: "Which offering would permit me to propose an equivalent value?",
          },
        ],
      },
      {
        name: "Strategic Groups",
        logo: "strategic-groups",
        head: [
          {
            id: 0,
            name: "Which new offering can I create by combining the characteristics of 2 or several strategic groups?",
          },
        ],
      },
      {
        name: "Decision Chain",
        logo: "decision-chain",
        head: [
          {
            id: 0,
            name: "How to create new value in focusing on the key issues of one of the actors of the decision chain? (influencers, prescribers, decision-makers, etc.)",
          },
        ],
      },
      {
        name: "Complementary Products & Services",
        logo: "complementary-products-services",
        head: [
          {
            id: 0,
            name: "Which add-ons to my offering would significantly transform its use?",
          },
        ],
      },
      {
        name: "Emotional & Functional Appeal",
        logo: "emotional-functional-appeal",
        head: [
          {
            id: 0,
            name: "Is my industry driven more by functional or emotional appeal?",
          },
          {
            id: 1,
            name: "How can I make my offering more functional? More emotional?",
          },
        ],
      },
    ];
    if (graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);
      docRef
        .doc(graphId)
        .update({
          six_path: true,
        })
        .then(() => {
          paths.map((item, index) => {
            firebase
              .firestore()
              .collection(`events/${currentEvent?.id}/bboa/${graphId}/six_path`)
              .doc(`${index}`)
              .set({
                head: item.head,
                name: item.name,
                logo: item.logo,
              });
          });
        });
    }
  };

  const createNewSixPath = () => {
    let paths = [
      {
        name: "Time Trends",
        logo: "time-trends",
        definition:
          "Looking 10 to 20 years ahead, industry, technology, economy, sociology, life-styles, new uses, etc.",
        head: [
          {
            id: 0,
            name: "How can I create unrivalled value for the customer by becoming an actor of the trends that impact my market?",
          },
        ],
      },
      {
        name: "Alternative Industries",
        logo: "alternative-industries",
        definition:
          "Alternatives are offerings that the customer will consider somewhat similar. There are two types of alternatives: 1-Similar objectives, different form 2-Comparable experiences",
        head: [
          { id: 0, name: "Alternative" },
          {
            id: 1,
            name: "Why do my noncustomers choose this alternative?",
          },
          {
            id: 2,
            name: "Which offering would permit me to propose an equivalent value on my industry?",
          },
        ],
      },
      {
        name: "Strategic Groups",
        logo: "strategic-groups",
        definition:
          "Strategic groups picture the competitive landscape for your industry, as seen by the customer. A Strategic Group is a cluster of similar competitors following similar strategies",
        head: [
          { id: 0, name: "Strategic groups of my industry" },
          {
            id: 1,
            name: "Which new offering can I create by combining the characteristics of 2 or several strategic groups?",
          },
        ],
      },
      {
        name: "Decision Chain",
        logo: "decision-chain",
        definition:
          "All the ecosystem stakeholders such as: influencers, prescribers or decision-makers",
        head: [
          {
            id: 0,
            name: "Who are the influencers, prescribers or decision-makers?",
          },
          {
            id: 1,
            name: "How to create new value in focusing on one of them?",
          },
        ],
      },
      {
        name: "Complementary Products & Services",
        logo: "complementary-products-services",
        definition:
          "From a consumer point of view, all the offerings that could be naturally associated with the original offering, they are all parts of the same value chain",
        head: [
          { id: 0, name: "Complementary products or services" },
          {
            id: 1,
            name: "How my offering would be significantly transformed in adding one of these?",
          },
        ],
      },
      {
        name: "Emotional & Functional Appeal",
        logo: "emotional-functional-appeal",
        definition:
          "All the functional and the emotional descriptors for a consumer",
        head: [
          {
            id: 0,
            name: "Is my industry driven more by functional or emotional appeal?",
          },
          {
            id: 1,
            name: "How can I make my offering more functional? More emotional?",
          },
        ],
      },
    ];
    if (graphId) {
      setUpdatingSixPath(true);

      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);

      docRef
        .doc(graphId)
        .update({
          six_path_new: true,
        })
        .then(() => {
          paths.map((item, index) => {
            firebase
              .firestore()
              .collection(
                `events/${currentEvent?.id}/bboa/${graphId}/six_path_new`
              )
              .doc(`${index}`)
              .set({
                head: item.head,
                definition: item.definition,
                name: item.name,
                logo: item.logo,
              });
          });
        })
        .catch((error) => {
          console.error("Error updating six path: ", error);
        })
        .finally(() => setUpdatingSixPath(false));
    }
  };

  const createTobe = async () => {
    if (graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`);

      if (products?.length > 0) {
        const productsToBe = await Promise.all(
          products?.map(async (item) => {
            const productToBe = await docRef
              .doc(graphId)
              .collection("to_be")
              .doc(item.id);

            const productToBeData = await productToBe.get();

            if (productToBeData.exists) {
              console.log(productToBeData.data);
              return true;
            } else {
              const { color, name } = item;
              const addProductToBe = productToBe.set({
                color: color,
                name: name,
              });
              return addProductToBe ? true : false;
            }
          })
        );
      }

      docRef
        .doc(graphId)
        .update({
          to_be_created: true,
        })
        .then(() => {
          setToBe(true);
        });
    }
  };

  const revokeSharingAccess = (project_id) => {
    const projectRef = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/`)
      .doc(`${project_id}`);

    projectRef
      .update({
        assignedToCategory: "",
      })
      .then(() => {
        toast.dark("Access revoked", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => console.error("Revoke sharing access: ", error));
  };

  const UserDetails = ({ uid, idx, project_id }) => {
    const userDetails = allUsers?.filter((item) => item.uid === uid);
    return (
      <div className="d-flex align-items-center mb-1" key={idx}>
        <div className="mr-2">
          <Button
            size="sm"
            variant="danger"
            onClick={() => revokeSharingAccess(uid, project_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">
            {idx + 1}. {userDetails[0]?.label} ({userDetails[0]?.email})
          </p>
        </div>
      </div>
    );
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const shareToAllHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${project_id}`);

      projectRef
        .set(
          {
            assignedToAll: true,
            assignedToCategory: "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared to all successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <>
      <div className="h-100 montserrat-font">
        <EventLeftbar mobile />
        <div className="container-fluid h-100" style={{ overflow: "scroll" }}>
          <EventNavbar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <EventLeftbar />
            <div
              className="h-100 col-md-11 p-3"
              style={{ overflow: "scroll" }}
              data-theme={darkTheme ? "dark" : "light"}
            >
              <React.Fragment>
                <Card
                  className={
                    darkTheme
                      ? "bg-dark shadow-sm border border-dark mb-3"
                      : "bg-white shadow-sm border border-light mb-3"
                  }
                >
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div class="row">
                        <Button
                          className=" ml-3"
                          variant="transparent"
                          onClick={() => history.push(`/event/${id}/bboa`)}
                        >
                          <FontAwesomeIcon
                            className={darkTheme ? "text-light" : "text-dark"}
                            icon={faArrowLeft}
                          />
                        </Button>
                        <div className="p-2">
                          <h5 className="mb-0">{title}</h5>
                          <h6 className="mb-0">
                            Industry Perimeter: {industry}
                          </h6>
                        </div>
                      </div>
                      <p className="ml-5 small">
                        {moment(createdAt).format("LLL")}
                      </p>
                    </div>
                    <div>
                      {(createdBy !== currentUser.uid ||
                        assignedToAll ||
                        assignedTo?.includes(currentUser.uid)) && (
                        <div className="p-2">
                          <>
                            {allUsers?.map((item) => (
                              <>
                                {item.uid === createdBy && (
                                  <p className=" small">{`Created By ${item.firstName}`}</p>
                                )}
                              </>
                            ))}
                          </>
                        </div>
                      )}
                      {createdBy === currentUser.uid && (
                        <div className="p-2">
                          <Button
                            size="sm"
                            onClick={() => {
                              setShowShare(true);
                              //setSelectedProject(graph);
                            }}
                            className="text-uppercase font-weight-bold"
                          >
                            <FontAwesomeIcon icon={faShare} size="sm" />{" "}
                            <small>Share</small>
                          </Button>

                          <Modal
                            centered
                            show={showShare}
                            onHide={() => {
                              setShowShare(false);
                              setSelectedUsers([]);
                            }}
                          >
                            <Modal.Header
                              closeButton
                              className={
                                darkTheme
                                  ? "bg-dark border-secondary pb-1"
                                  : "bg-white border-light pb-1"
                              }
                            >
                              <div className="d-flex flex-column">
                                <p className="h4 mb-0">Share your project</p>
                                {/* {assignedTo?.length > 0 ? (
                                  <p className="mb-0">
                                    Shared with {assignedTo?.length} member
                                    {assignedTo?.length > 1 ? "s" : ""}
                                  </p>
                                ) : (
                                  <p className="mb-0">Not yet shared</p>
                                )} */}
                                {assignedToCategory || assignedToAll ? (
                                  <p className="mb-0">
                                    Shared with{" "}
                                    {assignedToAll ? (
                                      <b>All</b>
                                    ) : (
                                      <b>
                                        {getAttendeeCategoryName(
                                          assignedToCategory
                                        )}
                                      </b>
                                    )}{" "}
                                    attendee(s)
                                  </p>
                                ) : (
                                  <p className="mb-0">Not yet shared</p>
                                )}
                              </div>
                            </Modal.Header>
                            <Modal.Body className="text-dark">
                              {assignedTo?.length > 0 && (
                                <div className="mb-3">
                                  <p className="mb-2 text-uppercase small">
                                    Already shared with:
                                  </p>
                                  {assignedTo?.map((item, idx) => (
                                    <UserDetails
                                      uid={item}
                                      idx={idx}
                                      project_id={graphId}
                                    />
                                  ))}
                                </div>
                              )}

                              <p className="mb-1">
                                Select category to share your project
                              </p>
                              {/* <Select
                                isMulti
                                placeholder="Select people"
                                className="w-100 mb-2"
                                options={allUsers?.filter(
                                  (item) => !assignedTo?.includes(item.uid)
                                )}
                                onChange={(e) =>
                                  setSelectedUsers(e?.map((item) => item.uid))
                                }
                              /> */}

                              <Select
                                placeholder="Attendee Category"
                                options={attendeeCategoryList?.map((item) => {
                                  return {
                                    label: `${item.name}`,
                                    value: `${item.id}`,
                                  };
                                })}
                                onChange={(e) =>
                                  setSelectedAttendeeCategory(e.value)
                                }
                                className="w-100 mb-2 text-dark"
                              ></Select>

                              <Button
                                className="w-100 mb-2"
                                onClick={() => handleSelectedShare(graphId)}
                              >
                                Share
                              </Button>
                              {assignedToCategory && (
                                <>
                                  <Button
                                    variant="none"
                                    className="w-100 text-danger shadow-sm mb-2 border-danger"
                                    onClick={(e) => {
                                      setShowRevokeModal(true);
                                    }}
                                  >
                                    Revoke Access from{" "}
                                    <b>
                                      {" "}
                                      {getAttendeeCategoryName(
                                        assignedToCategory
                                      )}
                                    </b>
                                  </Button>
                                  <Modal
                                    centered
                                    show={showRevokeModal}
                                    onHide={() => setShowRevokeModal(false)}
                                  >
                                    <Modal.Header
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                      closeButton
                                    >
                                      <Modal.Title>Revoke Access</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      Are you sure you want to Revoke Access?
                                    </Modal.Body>
                                    <Modal.Footer
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setShowRevokeModal(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={(e) =>
                                          revokeSharingAccess(graphId)
                                        }
                                      >
                                        Revoke Access
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </>
                              )}
                              {assignedToCategory && (
                                <p className="small mb-0 text-muted">
                                  "{title}" is already being shared with{" "}
                                  <b>
                                    {getAttendeeCategoryName(
                                      assignedToCategory
                                    )}
                                  </b>
                                </p>
                              )}

                              <p className="my-2 text-center">OR</p>

                              {!assignedToAll && (
                                <Button
                                  className="w-100 mb-2"
                                  onClick={() => shareToAllHandler(graphId)}
                                >
                                  Share with Everyone
                                </Button>
                              )}
                              {assignedToAll && (
                                <Button
                                  variant="none"
                                  className="w-100 text-danger shadow-sm border-danger"
                                  onClick={(e) => {
                                    revokeAccessFromAll(graphId);
                                  }}
                                >
                                  Revoke Access from <b>all</b>
                                </Button>
                              )}
                            </Modal.Body>
                          </Modal>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </React.Fragment>

              <React.Fragment>
                <ProjectSettings
                  errcCreated={errcCreated}
                  title={title}
                  setTitle={setTitle}
                  currentCustomers={currentCustomers}
                  setCurrentCustomers={setCurrentCustomers}
                />
              </React.Fragment>

              <br />

              <React.Fragment>
                <Card
                  className={
                    darkTheme
                      ? "bg-dark shadow-sm border border-dark mb-3"
                      : "bg-white shadow-sm border border-light mb-3"
                  }
                >
                  <Card.Header className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="mb-0 text-uppercase">
                        1. As Is Strategy Canvas
                      </h5>
                    </div>
                    <div className="d-flex">
                      <Dropdown className="pointer no-dropdown-caret">
                        <Dropdown.Toggle
                          as="div"
                          id="dropdown-basic"
                          className="btn btn-secondary btn-sm text-uppercase"
                        >
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportJpeg("as-is-canvas", `${title}_as-is`)
                            }
                          >
                            Download JPEG Image
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportPng("as-is-canvas", `${title}_as-is`)
                            }
                          >
                            Download PNG Image
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-1" id="as-is-canvas">
                    <div className="">
                      <HighchartsReact
                        constructorType={"chart"}
                        highcharts={Highcharts}
                        options={options}
                      />
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <div className="row-reverse"></div>
                            {asisFactors
                              ?.sort((a, b) => a.position - b.position)
                              ?.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={item.position}
                                  isDragDisabled={item.name === "Price"}
                                  disableInteractiveElementBlocking={
                                    item.name === "Price"
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={
                                        index == 0
                                          ? "btn btn-light sm"
                                          : "btn btn-light sm"
                                      }
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // style={getItemStyle(
                                      //   snapshot.isDragging,
                                      //   provided.draggableProps.style
                                      // )}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 text-uppercase small">
                        <b>Pro tips</b>
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        className="mb-0 text-uppercase small text-muted ml-2"
                        onClick={() => setShowProTips((prev) => !prev)}
                        title={
                          showProTips
                            ? "Click to hide pro tips"
                            : "Click to show pro tips"
                        }
                      >
                        <b>({showProTips ? "Hide" : "Show"})</b>
                      </p>
                    </div>
                    <ol
                      className={
                        showProTips ? "px-3 mb-1 pb-1" : "px-3 mb-1 pb-1 d-none"
                      }
                    >
                      <li>
                        <small>
                          Drag <b>key elements</b> to change their position on
                          x-axis.
                        </small>
                      </li>
                      <li>
                        <small>
                          Drag points on y-axis to change their value.
                        </small>
                      </li>
                      <li>
                        <small>
                          To add more <b>Products / Services</b> and{" "}
                          <b>Key elements</b> go to project settings.
                        </small>
                      </li>
                      <li>
                        <small>
                          To hide or show <b>Product / Service</b> graph click
                          on the Legend below.
                        </small>
                      </li>
                    </ol>
                  </Card.Footer>
                </Card>
              </React.Fragment>

              <br />

              <React.Fragment>
                <AsIsConclusions
                  event_id={currentEvent?.id}
                  project_id={graphId}
                />
              </React.Fragment>

              <br />

              {threeTier && (
                <React.Fragment>
                  <Tier />
                </React.Fragment>
              )}

              {!threeTier && (
                <Button
                  className="w-100 mb-2"
                  onClick={() => createThreeTier()}
                >
                  Create Identification of Noncustomers
                </Button>
              )}

              <br />

              <div>
                {buyerExpAuditCreated && <Table />}
                {!buyerExpAuditCreated && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createBuyerExpAudit()}
                  >
                    Create Buyer Utility Map
                  </Button>
                )}

                <br />

                {threeTier && (
                  <React.Fragment>
                    <KeyNeedOfNonCustomers
                      event_id={currentEvent?.id}
                      project_id={graphId}
                    />
                    <br />
                  </React.Fragment>
                )}

                {!updateSixPath && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createNewSixPath()}
                    disabled={updatingSixPath}
                    variant={sixPath ? "success" : "primary"}
                  >
                    {updatingSixPath ? (
                      <span>
                        {sixPath ? "Updating Six-Path" : "Creating Six-Path"}
                      </span>
                    ) : (
                      <span>
                        {sixPath ? "Update Six-Path" : "Create Six-Path"}
                      </span>
                    )}
                  </Button>
                )}
                {sixPath || updateSixPath ? <SixPath /> : null}
                {sixPath || updateSixPath ? (
                  <React.Fragment>
                    <IdeationTable
                      event_id={currentEvent?.id}
                      project_id={graphId}
                    />
                  </React.Fragment>
                ) : null}

                {/* {sixPath && updateSixPath && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => setShowOldSixPath((prev) => !prev)}
                    variant="secondary"
                  >
                    {showOldSixPath ? "Hide old six-path" : "Show old six-path"}
                  </Button>
                )} */}
                {/* {sixPath &&
                  updateSixPath &&
                  (showOldSixPath ? <SixPath old={true} /> : null)} */}
                {/* {!sixPath && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createSixPath()}
                  >
                    Create Six-Path
                  </Button>
                )} */}

                <br />

                {errcCreated ? (
                  <>
                    <ErrcGrid title={title} />
                  </>
                ) : (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createErrcGrid()}
                  >
                    Create Blue Ocean Matrix
                  </Button>
                )}

                <br />

                {toBe && <ToBe title={industry} />}
                {!toBe && (
                  <Button className="w-100 mb-2" onClick={() => createTobe()}>
                    Create To Be Strategy Canvas
                  </Button>
                )}
              </div>

              <br />
            </div>
            {/* <div
              className="col-md-4 h-100 px-0 d-none d-md-block  "
              style={{ overflow: "scroll" }}
            >
              <EventChat
                event_id={currentEvent?.id}
                tabs={[
                  {
                    name: "Chat",
                    type: "chat",
                    chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                  },
                  {
                    name: "People",
                    type: "people",
                  },
                ]}
              />
            </div> */}
            {/* Chat Mobile view */}
            {/* {showChat && (
              <div
                className="shadow-lg border border-muted"
                style={{
                  height: "55vh",
                  position: "fixed",
                  bottom: "17vh",
                  left: 10,
                }}
              >
                <EventChat event_id={currentEvent?.id} />
              </div>
            )}
            <div
              style={{
                position: "fixed",
                height: 45,
                width: 45,
                bottom: "10.5vh",
                left: 5,
              }}
              className="bg-primary rounded-circle d-block d-md-none"
            >
              <a
                className="d-flex align-items-center justify-content-center"
                onClick={() => setShowChat((prev) => !prev)}
                style={{
                  height: 45,
                  width: 45,
                }}
              >
                <FontAwesomeIcon
                  className="text-white"
                  icon={showChat ? faTimes : faComment}
                  style={{ fontSize: 20 }}
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    currentEvent: state.currentEvent,
    roomId: state.roomId,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    series: state.series,
    asisFactors: state.asisFactors,
    darkTheme: state.darkTheme,
  }),
  {
    setFactors,
    setProducts,
    setGraphId,
    setAsIsFactors,
  }
)(Project);
